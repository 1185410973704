import { Route } from 'react-router-dom';

import { lazyImport } from 'utils/lazyImport';

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');

const { CCDeliveryOrderDetailPage } = lazyImport(
  () => import('features/orderDetails/pages/CCOrderDetailPage'),
  'CCDeliveryOrderDetailPage'
);

export const publicRoutes = (
  <>
    <Route path="auth/*" element={<AuthRoutes />} />

    {/* ccog => Common Carrier Oder Group */}
    <Route key={'ccDelivery'} path={`ccog/:id`} element={<CCDeliveryOrderDetailPage />} />
  </>
);
