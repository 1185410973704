import { Box, IconButton, Text, Flex } from '@chakra-ui/react';
import { css, SerializedStyles } from '@emotion/react';
import { DateTime } from 'luxon';
import { useState, useEffect, useMemo } from 'react';
import { DatePickerProps } from 'react-datepicker';
import { useSearchParams, useLocation } from 'react-router-dom';

import { DateRangePicker } from 'components/DateRangePicker';
import { FAIcon } from 'components/FAIcon';
import { PandaButton } from 'components/PandaButton';
import { primaryBtnStyle } from 'components/PandaButton/styles';
import { DATE_FORMAT, COMMONS } from 'globalConstants';
import { useDateHelpers } from 'hooks/useDateHelpers';

import { dateRangeContainerStyle, dateRangeValueStyle, prevNextBtnStyle } from './styles';

export interface DateRangePickerWithTodayPropsType {
  maxDate?: Date;
  isTodayInRange?: boolean;
  disableFutureDates?: boolean;
  limitMaxDaysSelection?: number;
  customStyles?: SerializedStyles;
  datePickerProps?: DatePickerProps;
}

const { YYYY_MM_DD, MMM_DD_YYYY } = DATE_FORMAT;
const { START_DATE, END_DATE, SUBTRACT, PREV } = COMMONS;

const PREVIOUS_DAYS_GAP = 6;
const NEXT_DAYS_GAP = 7;

export const DateRangePickerWithToday: React.FC<DateRangePickerWithTodayPropsType> = (props) => {
  const {
    isTodayInRange = false,
    customStyles = css({}),
    disableFutureDates = false,
    limitMaxDaysSelection = -1,
    datePickerProps = {},
  } = props;
  const location = useLocation();
  const { getFormattedTimeStamp, operationInDate, getTodayDateTime, getDateTimeInUnix } =
    useDateHelpers();

  const defaultTodayDate = getTodayDateTime();
  const defaultFromDate = operationInDate({
    dateTime: defaultTodayDate,
    duration: PREVIOUS_DAYS_GAP,
    optType: SUBTRACT,
  });
  const [, setSearchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState([defaultFromDate, defaultTodayDate]);

  const convertToUnixValue = (dateToConvert: string) => {
    let tempUnixValue = 0;
    const tempDate = DateTime.fromFormat(dateToConvert, YYYY_MM_DD);
    if (tempDate.isValid) {
      tempUnixValue = getDateTimeInUnix(tempDate);
      return { value: tempUnixValue, isValid: true };
    }
    const validDate = DateTime.fromISO(new Date(dateToConvert)?.toISOString());
    tempUnixValue = getDateTimeInUnix(validDate);
    return { value: tempUnixValue, isValid: false };
  };
  const getFormattedDate = (dateToFormat: string, format?: string) => {
    const tempUnixValue = convertToUnixValue(dateToFormat);
    const tempFormattedValue = getFormattedTimeStamp(tempUnixValue.value, format);
    return { value: tempFormattedValue, isValid: tempUnixValue.isValid };
  };

  const getAdjustedDates = (fromDateValue: string, toDateValue: string) => {
    const tempTodayDate = convertToUnixValue(defaultTodayDate);
    const fromDateUnixValue = convertToUnixValue(fromDateValue);
    const toDateUnixValue = convertToUnixValue(toDateValue);
    const tempFromDate = fromDateUnixValue > tempTodayDate ? defaultTodayDate : fromDateValue;
    const tempToDate = toDateUnixValue > tempTodayDate ? defaultTodayDate : toDateValue;
    return { tempFromDate, tempToDate };
  };

  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location?.search);
      const queryStartDate = query.get(START_DATE) || defaultFromDate;
      const queryEndDate = query.get(END_DATE) || defaultTodayDate;

      const { value: tempStartDate, isValid: isStartDateValid } = getFormattedDate(queryStartDate);
      const { value: tempEndDate, isValid: isEndDateValid } = getFormattedDate(queryEndDate);

      let startDate = tempStartDate;
      let endDate = tempEndDate;
      if (disableFutureDates) {
        const { tempFromDate, tempToDate } = getAdjustedDates(startDate, endDate);
        startDate = tempFromDate;
        endDate = tempToDate;
        setSearchParams(query.toString());
      }

      query.set(START_DATE, startDate);
      query.set(END_DATE, endDate);

      if (!isStartDateValid || !isEndDateValid) {
        setSearchParams(query.toString());
      } else {
        setDateRange([startDate, endDate]);
      }
    }
    // getAdjustedDates, setSearchParams, getFormattedDate not needed in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, disableFutureDates]);

  const handleSetTodayDate = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('start_date', defaultTodayDate);
    searchParams.set('end_date', defaultTodayDate);
    setSearchParams(searchParams.toString());
    setDateRange([isTodayInRange ? defaultFromDate : defaultTodayDate, defaultTodayDate]);
  };

  const isNextButtonDisabled = useMemo(() => {
    if (disableFutureDates) {
      if (defaultTodayDate === dateRange?.[1]) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [disableFutureDates, defaultTodayDate, dateRange]);

  const pushUrlParams = (prevOrNext: 'prev' | 'next') => {
    let fromDate: string | Date = '';
    let toDate: string | Date = '';

    if (prevOrNext === PREV) {
      fromDate = operationInDate({
        dateTime: dateRange?.[0],
        duration: NEXT_DAYS_GAP,
        optType: SUBTRACT,
      });
      toDate = operationInDate({
        dateTime: fromDate,
        duration: PREVIOUS_DAYS_GAP,
      });
    } else {
      fromDate = operationInDate({
        dateTime: dateRange?.[0],
        duration: NEXT_DAYS_GAP,
      });
      toDate = operationInDate({
        dateTime: fromDate,
        duration: PREVIOUS_DAYS_GAP,
      });
      if (disableFutureDates) {
        const { tempFromDate, tempToDate } = getAdjustedDates(fromDate, toDate);
        fromDate = tempFromDate;
        toDate = tempToDate;
      }
    }

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('start_date', fromDate.toString());
    searchParams.set('end_date', toDate.toString());
    setSearchParams(searchParams.toString());

    const fromDateValue = getFormattedDate(fromDate).value;
    const toDateValue = getFormattedDate(toDate).value;
    setDateRange([fromDateValue, toDateValue]);
  };

  const handleCalendarSelect = ([fromDateValue, toDateValue]: [Date, Date]) => {
    const tempFromDate = getDateTimeInUnix(DateTime.fromISO(fromDateValue.toISOString()));
    const tempToDate = getDateTimeInUnix(DateTime.fromISO(toDateValue.toISOString()));
    const tempFromFormatted = getFormattedTimeStamp(tempFromDate);
    const tempToFormatted = getFormattedTimeStamp(tempToDate);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('start_date', tempFromFormatted);
    searchParams.set('end_date', tempToFormatted);
    setSearchParams(searchParams.toString());

    setDateRange([tempFromFormatted, tempToFormatted]);
  };

  return (
    <Box css={customStyles}>
      <Flex alignItems={'center'} gap={2}>
        <Box sx={dateRangeContainerStyle}>
          <IconButton
            aria-label="Previous Date"
            sx={prevNextBtnStyle}
            icon={<FAIcon variant="solid" icon="angle-left" />}
            onClick={() => pushUrlParams('prev')}
          />
          <DateRangePicker
            onValueChange={handleCalendarSelect}
            fromDate={DateTime.fromISO(dateRange?.[0]).toJSDate()}
            toDate={DateTime.fromISO(dateRange?.[1]).toJSDate()}
            limitMaxDaysSelection={limitMaxDaysSelection}
            customInput={
              <Text sx={dateRangeValueStyle} px={2}>
                {getFormattedDate(dateRange?.[0], MMM_DD_YYYY).value} -{' '}
                {getFormattedDate(dateRange?.[1], MMM_DD_YYYY).value}
              </Text>
            }
            selectedDate={DateTime.fromISO(dateRange?.[0]).toJSDate()}
            {...datePickerProps}
          />
          <IconButton
            aria-label="Next Date"
            sx={prevNextBtnStyle}
            icon={<FAIcon variant="solid" icon="angle-right" />}
            onClick={() => pushUrlParams('next')}
            isDisabled={isNextButtonDisabled}
          />
        </Box>
        <PandaButton btnLabel="Today" sx={primaryBtnStyle} onClick={handleSetTodayDate} />
      </Flex>
    </Box>
  );
};
